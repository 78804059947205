const navSlide = () => {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.navLinks');
    const head = document.querySelector('.navLogo');
    const navLinks = document.querySelectorAll('.navLinks li');
    
    if(burger == null){
        console.log(burger);
        console.log(nav);
        return;
    }
    burger.addEventListener('click', () => {
    nav.classList.toggle('navActive');
    head.classList.toggle('logoActive');
    burger.classList.toggle('burgerActive');
    navLinks.forEach((link, index) => {
      if (link.style.animation){
        link.style.animation = '';
      } else {
          link.style.animation = `navLinkFade 0.3s ease forwards ${index / 7 + 0.3}s`;
        }
      });
    
      burger.classList.toggle('toggle');
    
      });
  }
    
   
exports.onRouteUpdate = () => {
    navSlide();
  };